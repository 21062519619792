export interface Order {
  _id: string;
  additionalInfo: CustomObject[];
  account: {
    _id: string;
    name: string;
    accountNumber: string;
    phone: string;
  };
  effectiveDate: Date;
  orderNumber: string;
  erpOrderNumber: string;
  source: OrderSource;
  status: OrderStatus;
  itemsCount: number;
  totalSell: number;
  totalCost: number;
  total: number;
  cost: number;
  address: {
    _id: string;
    name: string;
    locationId: string;
    city: string;
    country: string;
    postalCode: string;
    state: string;
    street: string;
  };
  lastModifiedDate: Date;
  createdDate: Date;
}

export interface CustomObject {
  label: string;
  value:
    | string
    | number
    | boolean
    | string[]
    | ShortObject
    | ShortObject[]
    | ConditionalString;
  type: string;
  specification?: boolean;
  category?: string;
  userValue?:
    | string
    | number
    | boolean
    | string[]
    | ShortObject
    | ShortObject[]
    | ConditionalString;
  [k: string]: any;
}

interface ShortObject {
  _id: string;
  name: string;
  description: string;
}

interface ConditionalString {
  value: string;
  condition: boolean;
}

export const enum OrderStatus {
  Draft = 'Draft',
  Activated = 'Activated',
  Delivered = 'Delivered',
  Invoiced = 'Invoiced',
  Openorder = 'Open order',
  OpenOrder = 'Open Order',
  Backorder = 'Backorder',
  Accepted = 'Accepted',
  Canceled = 'Canceled',
  Declined = 'Declined',
  Expired = 'Expired',
  OrderIssue = 'Order Issue',
  PartiallyShipped = 'Partially Shipped',
  Processing = 'Processing',
  Saved = 'Saved',
  Sent = 'Sent',
  Shipped = 'Shipped',
  Shipping = 'Shipping',
  Submitted = 'Submitted'
}

export const enum OrderSource {
  CustomerService = 'Customer Service',
  Ecommerce = 'Ecommerce',
  SalesRep = 'Sales Rep'
}

export interface OrderItem {
  _id: string;
  product: {
    _id: string;
    name: string;
    sku: string;
    images: string[];
    vendor: {
      _id: string;
      name: string;
    };
  };
  status: OrderItemStatus;
  quantity: number;
  deliverQuantity: number;
  backorderQuantity: number;
  sell: number;
  cost: number;
  total: number;
  orderId: string;
  lastModifiedDate: Date;
  createdDate: Date;
}

export const enum OrderItemStatus {
  Saved = 'Saved',
  Submitted = 'Submitted',
  OpenOrder = 'Open Order',
  Canceled = 'Canceled',
  Invoiced = 'Invoiced',
  Delivered = 'Delivered',
  Backorder = 'Backorder',
  ERPTransferError = 'ERP Transfer Error'
}

export interface Backorder {
  _id: string;
  accountNumber: string;
  orders: {
    orderId: string;
    erpOrderNumber: string;
  }[];
  oldestOrderDate: Date;
  totalSell: number;
  totalCost: number;
  customerName: string;
  totalBackorders: number;
  totalItems: number;
}

export interface AccountBackorders {
  totalCost: number;
  totalSell: number;
  order: Order;
}
