import { Category } from '../categories';

export interface Product {
  _id: string;
  productCode: string;
  name: string;
  active: boolean;
  cost?: number;
  description: string;
  discontinued: boolean;
  dropship: boolean;
  manufacturerProductNumber: string;
  price: number | null;
  imageUrl: string;
  packSize: string;
  unit: string;
  special: boolean;
  procurementStopped: boolean;
  salesStopped: boolean;
  created_on: string;
  updated_on: string;
  last_modified: string;
  images: ProductImage[] | null;
  inventory: ProductInventory[] | null;
  sku: string | null;
  manufacturerSku: string | null;
  vendor: Vendor | null;
  categories: Omit<Category, 'children'>[] | null;
  custom?: DynamicAttribute;
  privateLabel?: boolean;
  promotions: string[] | null;
  productAssociations?: string[];
}

export interface ProductImage {
  _id: string;
  path: string;
  size: string;
  name: string;
}

export interface ProductInventory {
  _id: string;
  productId: string;
  siteId: string;
  warehouseId: string;
  availableOnHand: number;
  availableOrderedQuantity: number;
  onHandQuantity: number;
  onOrderQuantity: number;
  orderedQuantity: number;
  onTransferQuantity: number;
  warehouse: ProductWarehouse | null;
}

export interface ProductWarehouse {
  _id: string;
  code: string;
  name: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  zip: string;
}
export interface Vendor {
  _id: string;
  vendorId: string;
  name: string;
}

export interface DynamicAttribute {
  attribute: RecordType;
  ship_lead_time__business_days?: string;
}

export interface RecordType {
  value: string | boolean | string[];
  label: string;
  type: CustomPropertyType;
  specification: boolean;
}

export enum CustomPropertyType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Link = 'link',
  HTML = 'html',
  ArrayString = 'arrayString',
  LabeledImageArray = 'LabeledImageArray'
}

export enum CompanyProductUserType {
  Distributor = '0',
  Manufacturer = '1'
}
